import { useEffect, useState } from "react";
import InvestorServices from "../utils/InvestorServices";
import { Helmet } from "react-helmet-async";
import InvestorPreviewCard from "../components/InvestorPreviewCard";
import { useMainContext } from "../../../components/Context/MainContext";
import { CloseOutlined, FilterOutlined, HeartOutlined, LeftOutlined, LoadingOutlined, MenuFoldOutlined, PlusOutlined, SearchOutlined } from "@ant-design/icons";
import Sidebar from "../../../components/Sidebar/Sidebar";
import { Link, useParams, useSearchParams } from "react-router-dom";
import InvestorPreviewSkeleton from "../../../components/Skeleton/InvestorPreviewSkeleton";
import { Input, Pagination } from "antd";
import FilterDrawer from "../../../components/Drawer/FilterDrawer";
import { useEventTracker } from "../../../components/Context/EventTrackingContext";
import InvestorSimpleListCard from "../components/InvestorSimpleListCard";
import { useAuth } from "../../../utils/useAuth";

function InvestorsPage() {
    const {stage, location, industry} = useParams();
    const {user} = useAuth();
    const [searchParams,setSearchParams] = useSearchParams();
    const {vcClickEvent,customClickEvent} = useEventTracker();
    const {setSidebarCollapsed,setIsLoginModalVisible} = useMainContext();
    const [investorList,setInvestorList] = useState(null);
    const [investorCount,setInvestorCount] = useState(null);
    const [loading,setLoading] = useState(false);
    const [page,setPage] = useState(0);
    const [searchValue,setSearchValue] = useState('');
    const [savedInvestorList,setSavedInvestorList] = useState([]);
    const [isFilterDrawerVisible,setIsFilterDrawerVisible] = useState(false);

    const getInvestors = async () => {
        setLoading(true);
        let queryParams = {
            page:page
        }
        if(searchParams.get('stage')){
            queryParams['stage'] = searchParams.get('stage').toLowerCase().split(' ').join('-');
            customClickEvent({action:'filter_investor_stage',label:searchParams.get('stage').toLowerCase()});
        }
        if(searchParams.get('type')){
            queryParams['type'] = searchParams.get('type').toLowerCase().split(' ').join('-');
            customClickEvent({action:'filter_investor_type',label:searchParams.get('type').toLowerCase()});
        }
        if(searchParams.get('industry')){
            queryParams['industry'] = searchParams.get('industry').toLowerCase().split(' ').join('-');
            customClickEvent({action:'filter_investor_industry',label:searchParams.get('industry').toLowerCase()});
        }
        if(searchParams.get('country')){
            queryParams['country'] = searchParams.get('country').toLowerCase().split(' ').join('-');
            customClickEvent({action:'filter_investor_country',label:searchParams.get('country').toLowerCase()});
        }
        if(searchParams.get('search')){
            queryParams['search'] = searchParams.get('search');
            customClickEvent({action:'search_investor',label:searchParams.get('search')});
        }
        if(stage){
            queryParams['stage'] = stage;
            customClickEvent({action:'filter_investor_stage',label:searchParams.get('stage').toLowerCase()});
        }
        if(location){
            queryParams['location'] = location;
            customClickEvent({action:'filter_investor_location',label:searchParams.get('location').toLowerCase()});
        }
        if(industry){
            queryParams['industry'] = industry;
            customClickEvent({action:'filter_investor_industry',label:searchParams.get('industry').toLowerCase()});
        }

        const investorsResponse = await InvestorServices.getInvestors(queryParams);
        setInvestorList(investorsResponse.results);
        setInvestorCount(investorsResponse.count);
        setLoading(false);
    }
    const onChangePage = (value) => {
        searchParams.set('page', value);
        setSearchParams(searchParams);
        setPage(value - 1);
    }
    const onSearch = (value) => {
        searchParams.set('search', value);
        searchParams.delete('page');
        setPage(0);
        setSearchParams(searchParams);
    }
    const formatTitleValue = (string) => {
        return string.split('-').map((value) => {return value.charAt(0).toUpperCase() + value.slice(1)}).join(' ');
    }
    const clickInvestor = (value) => {
        vcClickEvent({type:'investor',name:value?.key});
    }
    const removeSavedInvestor = (value) => {
        setSavedInvestorList(savedInvestorList.filter(x => x.key != value.key));
    }
    const clickCreateAccount = () => {
        setIsLoginModalVisible(true);
        customClickEvent({action:'create_account_click'});
    }
    const clickSave = (value) => {
        setSavedInvestorList(prev => [value,...prev])
    }

    useEffect(() => {
        window.scrollTo({top:0,behavior:'smooth'});
        if(searchParams.get('page') && investorList == null){
            setPage(searchParams.get('page') - 1);
            getInvestors();
        }
        else{
            getInvestors();
        }
        
    },[page,searchParams]);

    return(
        <div className="bg-black min-h-screen">
            <Helmet>
                <meta charSet="utf-8" />
                <title>{`VC Hunt - Find an Investor`}</title>
                <meta property="og:title" content={`VC Hunt - Find an Investor`}/>
                <meta name="description" content={`VC Hunt - Find an Investor`} />
                <link rel="canonical" href="https://www.vc-hunt.com" />
            </Helmet>
            <div className="text-left">
                <div className="grid grid-cols-1 md:grid-cols-4 lg:grid-cols-5 gap-4 w-full m-auto">
                    <div className="pl-8 py-8 static md:sticky top-0 h-screen">
                        <div className="flex flex-col text-white w-full md:w-11/12 h-full m-auto">
                            <div className="fixed top-0 left-0 right-0 bg-black h-20 p-4 shadow-lg flex items-center z-20 md:relative mb-4 mb-8 flex items-center">
                                <div className="flex-1">
                                    <Link to='/'>
                                        <h1>VC Hunt</h1>
                                    </Link>
                                </div>
                                <div className="block md:hidden text-2xl">
                                    <div className="rounded-xl bg-[#1B191C] px-3 aspect-square flex items-center justify-center" onClick={() => setSidebarCollapsed(true)}>
                                        <MenuFoldOutlined/>
                                    </div>
                                </div>
                            </div>
                            <div className="hidden md:block h-full">
                                <Sidebar />
                            </div>
                        </div>
                    </div>
                    <div className="col-span-1 md:col-span-3 lg:col-span-4 flex grid grid-cols-1 md:grid-cols-3 lg:grid-cols-5 p-2 md:p-0 bg-black">
                        <div className="col-span-1 md:col-span-3 flex flex-col items-start h-full text-white w-full md:w-10/12 lg:w-11/12 m-auto pb-0 lg:pb-12">
                            <div className="h-20 md:h-28 flex items-center mb-4 md:mb-0 bg-black relative md:sticky top-0 z-10 shadow-lg w-full h-auto">
                                <div className="pt-0 md:pt-4 pl-2 md:pl-0">
                                    <h1 className="leading-none mb-0.5">
                                        {
                                            (stage || location || industry)
                                            ? `Top${stage && ` ${formatTitleValue(stage)}`} Investors${location && ` in ${formatTitleValue(location)}`}${industry && ` for ${formatTitleValue(industry)} Startups`}`
                                            : 'Explore Investors'
                                        }
                                    </h1>
                                    {
                                        investorCount &&
                                            <div>
                                                <h4 className="m-auto font-medium">{investorCount} Results</h4>
                                            </div>
                                    }
                                </div>
                            </div>
                            <div className="sticky top-20 md:top-28 left-0 right-0 w-full bg-black pb-2 z-20 mb-2">
                                <div className="w-full md:w-11/12 flex gap-1">
                                    <div className="flex-1">
                                        <Input 
                                            className="w-full rounded-md bg-white h-full text-black"
                                            placeholder="Search Investors"
                                            variant="borderless"
                                            value={searchValue}
                                            onPressEnter={(value) => onSearch(value.target.value)}
                                            onChange={(value) => setSearchValue(value.target.value)}
                                            suffix={<SearchOutlined/>}
                                            size='large' />
                                    </div>
                                    <div onClick={() => setIsFilterDrawerVisible(true)} className="border w-fit p-2 text-center text-base rounded-md hover:font-semibold cursor-pointer">
                                        <FilterOutlined /> Filters
                                    </div>
                                </div>
                            </div>
                            <div className="flex flex-col gap-3 w-full md:w-11/12 mb-4">
                                {
                                    loading &&
                                        <div className="text-center text-xl">
                                            <LoadingOutlined />
                                        </div>
                                }
                                {
                                    investorList
                                    ?   investorList.length > 0
                                        ?
                                            investorList.map((value,index) =>
                                                <div key={`investor-${index}`} className="w-full" onClick={() => clickInvestor(value)}>
                                                    <InvestorPreviewCard values={value} onSave={clickSave} />
                                                </div>
                                            )
                                        :
                                            <div className="w-full aspect-video flex justify-center items-center">
                                                <div className="w-full p-2">
                                                    <div className="text-center mb-6">
                                                        <h2 className="leading-none m-0">
                                                            No Investors Found
                                                        </h2>
                                                    </div>
                                                    <div>
                                                        <div className="border w-fit p-2 px-8 text-center text-base rounded-md hover:font-semibold cursor-pointer m-auto">
                                                            <PlusOutlined/> Add a Investor
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                    :   [1,2,3,4].map((_,index) =>
                                            <div key={`investor-preview-${index}`}>
                                                <InvestorPreviewSkeleton />
                                            </div>
                                        )
                                }
                            </div>
                            {
                                investorCount &&
                                    <div className="w-full flex justify-center py-2">
                                        <Pagination pageSize={20} current={page + 1} total={investorCount} onChange={(value) => onChangePage(value)} showSizeChanger={false} />
                                    </div>
                            }
                        </div>
                        <div className="col-span-1 md:col-span-3 lg:col-span-2 w-full pt-4 pb-12">
                            <div className="relative md:sticky top-0 pt-0 lg:pt-28  w-full md:w-10/12 m-auto lg:m-0">
                                <div className="p-8 rounded-2xl bg-[#1B191C] text-white w-full md:w-11/12">
                                    <div className="mb-2">
                                        <h4 className="text-lg m-auto font-semibold">
                                            Your Saved Investors
                                        </h4>
                                    </div>
                                    {
                                        savedInvestorList && savedInvestorList.length > 0
                                        ?   <div className="w-full flex flex-col gap-2 pt-2 mb-8 max-h-[50vh] overflow-y-scroll">
                                                {
                                                    savedInvestorList.map((value,index) => 
                                                        <div key={`saved-investor-${index}`} className="flex items-center">
                                                            <div className="flex-1">
                                                                <a href={`/investor/${value.key}`} target="_blank">
                                                                    <InvestorSimpleListCard values={value} />
                                                                </a>
                                                            </div>
                                                            <div className="text-xs hover:font-darkgrey cursor-pointer hover:scale-105" onClick={() => removeSavedInvestor(value)}>
                                                                <CloseOutlined/>
                                                            </div>
                                                        </div>
                                                    )
                                                }
                                            </div>
                                        :   <div className="w-full p-2 bg-black text-center rounded-lg mb-2">
                                                <div className="flex items-center gap-2 w-fit m-auto">
                                                    <div>
                                                        <LeftOutlined/>
                                                    </div>
                                                    <div>
                                                        Click the 
                                                    </div>
                                                    <div className="p-2 px-3 text-base rounded-md w-fit hover:scale-110" style={{backgroundColor:'rgba(0,0,0,0.4)'}}>
                                                        <HeartOutlined />
                                                    </div>
                                                    <div>
                                                        to Save Investors
                                                    </div>
                                                </div>
                                            </div>
                                    }
                                    {
                                        !user &&
                                            <div className="w-full">
                                                <div className="mb-0.5 font-semibold">
                                                    To Save or Export The List:
                                                </div>
                                                <div onClick={clickCreateAccount} className="w-full bg-black p-2 text-center text-base rounded-md hover:font-semibold cursor-pointer">
                                                    Create an Account
                                                </div>
                                            </div>
                                    }
                                </div>
                            </div>
                        </div> 
                    </div>
                </div>
            </div>
            <FilterDrawer isFilterDrawerVisible={isFilterDrawerVisible} setIsFilterDrawerVisible={setIsFilterDrawerVisible} />
        </div>
    )

}

export default InvestorsPage;