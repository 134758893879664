import React, {Suspense} from 'react';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { Layout } from 'antd';
import './App.css';
import './output.css';
import './styles/index.css';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import HomePage from './containers/landing/HomePage';
import LoadingPage from './containers/landing/LoadingPage';
import InvestorsPage from './containers/investors/pages/InvestorsPage';
import InvestorPage from './containers/investors/pages/InvestorPage';
import { MainProvider } from './components/Context/MainContext';
import FundsPage from './containers/funds/pages/FundsPage';
import FundPage from './containers/funds/pages/FundPage';
import InvestmentsPage from './containers/investments/pages/InvestmentsPage';
import CompaniesPage from './containers/companies/pages/CompaniesPage';
import AllListsPage from './containers/landing/AllListsPage';
import { EventTrackingProvider } from './components/Context/EventTrackingContext';
import { AuthProvider } from './utils/useAuth';
import ForYouPage from './containers/for-you/pages/ForYouPage';

const { Content } = Layout;

function App() {	

	return (
		<div className="App">
			<Router>
				<HelmetProvider>
					<AuthProvider>
						<MainProvider>
							<EventTrackingProvider>
								<Layout>
									<Helmet>
										<meta charSet="utf-8" />
										<title>{`VC Hunt - Here to Help Startups Grow`}</title>
										<meta property="og:title" content={`VC Hunt - Here to Help Startups Grow`}/>
										<meta name="description" content={`VC Hunt - Here to Help Startups Grow`} />
										<link rel="canonical" href="https://www.vc-hunt.com" />
									</Helmet>
									<Content className='h-full'>
										<Routes>
											<Route path='/' element={<Suspense fallback={<LoadingPage />} ><HomePage /></Suspense>} />
											<Route path='/investors' element={<Suspense fallback={<LoadingPage />} ><InvestorsPage /></Suspense>} />
											<Route path='/investor/:key' element={<Suspense fallback={<LoadingPage />} ><InvestorPage /></Suspense>} />
											<Route path='/funds' element={<Suspense fallback={<LoadingPage />} ><FundsPage /></Suspense>} />
											<Route path='/fund/:key' element={<Suspense fallback={<LoadingPage />} ><FundPage /></Suspense>} />
											<Route path='/investments' element={<Suspense fallback={<LoadingPage />} ><InvestmentsPage /></Suspense>} />
											<Route path='/companies' element={<Suspense fallback={<LoadingPage />} ><CompaniesPage /></Suspense>} />
											<Route path='/recommendations' element={<Suspense fallback={<LoadingPage />} ><ForYouPage /></Suspense>} />
											<Route path='/all-lists' element={<Suspense fallback={<LoadingPage />} ><AllListsPage /></Suspense>} />
											<Route path='/:stage/investors/:location/:industry' element={<Suspense fallback={<LoadingPage />} ><InvestorsPage /></Suspense>} />
											<Route path='/:stage/funds/:location/:industry' element={<Suspense fallback={<LoadingPage />} ><FundsPage /></Suspense>} />
											<Route path='/:stage/vcs/:location/:industry' element={<Suspense fallback={<LoadingPage />} ><FundsPage /></Suspense>} />
										</Routes>
									</Content>
								</Layout>
							</EventTrackingProvider>
						</MainProvider>
					</AuthProvider>
				</HelmetProvider>
			</Router>
		</div>
	);
}

export default App;
