import { Link } from "react-router-dom";
import BasicTable from "./BasicTable";
import { useState } from "react";
import { ExportOutlined } from "@ant-design/icons";






function CompanyCellItem({value}){
    const [isHover,setIsHover] = useState(false);

    return(
        <Link to={`/fund/${value?.key}`} className="hover:text-white">
            <div className="flex items-center gap-1" onMouseEnter={() => setIsHover(true)} onMouseLeave={() => setIsHover(false)}>
                {value?.name}
                <div className={`transform duration-200 ease-in ${isHover ? "opacity-100" : "opacity-0"}`}>
                    <ExportOutlined/>
                </div>
            </div>
        </Link>
    )
}

const investmentColumns = [
    {
        title: 'Company',
        dataIndex: 'company',
        key: 'company',
        render: (company) => (
            company?.name
            ? <CompanyCellItem value={company} />
            : 'N/A'
        )
    },
    {
        title: 'Stage',
        dataIndex: 'stage',
        key: 'stage',
        render: (stage) => (
            stage?.name
            ?
                <Link to={`/investors?stage=${stage?.key}`}>
                    <div className="px-2 py-1 text-sm bg-black rounded-md w-fit cursor-pointer">
                        {stage?.name}
                    </div>
                </Link>
            : 'N/A'
        )
    },
    {
        title: 'Round Size',
        dataIndex: 'round_size',
        key: 'round_size'
    },
    {
        title: 'Total Raised',
        dataIndex: 'total_raised',
        key: 'total_raised',
    },
    {
        title: 'Investors',
        dataIndex: 'investors',
        key: 'investors',
        render: (investors) => (
            investors && investors.length > 0
            ?
                investors.map((value,index) =>
                    <a href={`/investor/${value?.key}`} target='_blank' key={`investor-${index}`}>
                        <div className="px-2 py-1 text-sm bg-black rounded-md w-fit cursor-pointer">
                            {value?.name}
                        </div>
                    </a>                
                )
            : 'N/A'
        )
    },
    {
        title: 'Funds',
        dataIndex: 'funds',
        key: 'funds',
        render: (funds) => (
            funds && funds.length > 0
            ?
                funds.map((value,index) =>
                    <a href={`/fund/${value?.key}`} target='_blank' key={`fund-${index}`}>
                        <div className="px-2 py-1 text-sm bg-black rounded-md w-fit cursor-pointer">
                            {value?.name}
                        </div>
                    </a>                
                )
            : 'N/A'
        )
    },
    {
        title: 'Date',
        dataIndex: 'date',
        key: 'date',
        render: (date) => (
            <div>{date ? date.split('T')[0] : 'N/A'}</div>
        )
    },
]

function InvestmentAdvancedTable({data}){
    return(
        <BasicTable pagination={true} dataSource={data.filter(x => x)} columns={investmentColumns}/>
    )
}

export default InvestmentAdvancedTable;