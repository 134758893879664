import { Helmet } from "react-helmet-async";
import Sidebar from "../../../components/Sidebar/Sidebar";
import { MenuUnfoldOutlined } from "@ant-design/icons";
import { useMainContext } from "../../../components/Context/MainContext";
import { useEffect, useState } from "react";
import InvestmentServices from "../utils/InvestmentServices";
import InvestmentAdvancedTable from "../../../components/Table/InvestmentAdvancedTable";
import { Link } from "react-router-dom";

function InvestmentsPage() {
    const {setSidebarCollapsed} = useMainContext();
    const [investmentCount,setInvestmentCount] = useState(null);
    const [investmentList,setInvestmentList] = useState(null);
    const [page,setPage] = useState(0);

    const getInvestments = async () => {
        const investmentsResponse = await InvestmentServices.getInvestments({page:page});
        console.log(investmentsResponse.results);
        setInvestmentList(investmentsResponse.results);
        setInvestmentCount(investmentsResponse.count);
    }
    
    useEffect(() => {
        getInvestments();
    },[])



    return(
        <div className="bg-black min-h-screen">
            <Helmet>
                <meta charSet="utf-8" />
                <title>{`VC Hunt - Investments`}</title>
                <meta property="og:title" content={`VC Hunt - Investments`}/>
                <meta name="description" content={`VC Hunt - Explore Investments by Investors and VCs`} />
                <link rel="canonical" href="https://www.vc-hunt.com" />
            </Helmet>
            <div className="text-left">
                <div className="grid grid-cols-1 md:grid-cols-4 lg:grid-cols-5 gap-4 w-full m-auto">
                    <div className="pl-8 py-8 static md:sticky top-0 h-screen">
                        <div className="flex flex-col text-white h-full w-full md:w-11/12 m-auto">
                            <div className="fixed top-0 left-0 right-0 bg-black h-20 p-4 shadow-lg flex items-center z-20 md:relative mb-4 mb-8 flex items-center">
                                <div className="flex-1">
                                    <Link to='/'>
                                        <h1>VC Hunt</h1>
                                    </Link>
                                </div>
                                <div className="block md:hidden text-2xl">
                                    <div className="rounded-xl bg-[#1B191C] px-3 aspect-square flex items-center justify-center" onClick={() => setSidebarCollapsed(true)}>
                                        <MenuUnfoldOutlined />
                                    </div>
                                </div>
                            </div>
                            <div className="hidden md:block h-full">
                                <Sidebar />
                            </div>
                        </div>
                    </div>
                    <div className="col-span-1 md:col-span-3 lg:col-span-4 flex grid grid-cols-1 p-2 md:p-0 bg-black">
                        <div className="flex flex-col items-start text-white w-full md:w-10/12 lg:w-11/12 m-auto h-full">
                            <div className="h-20 md:h-28 flex items-center mb-4 bg-black relative md:sticky top-0 z-10 shadow-lg w-full h-auto">
                                <div className="pt-0 md:pt-4 pl-4 md:pl-0">
                                    <h1 className="leading-none mb-0.5">
                                        Explore Investments
                                    </h1>
                                    {
                                        investmentCount &&
                                            <div>
                                                <h4 className="m-auto font-medium">{investmentCount} Results</h4>
                                            </div>
                                    }
                                </div>
                            </div>
                            <div className="w-full pb-12">
                                {
                                    investmentList && investmentList.length > 0 &&
                                        <InvestmentAdvancedTable data={investmentList}/>
                                }
                                <div className="p-8 rounded-2xl bg-[#1B191C] text-white w-full md:w-fit">
                                    <div className="mb-2">
                                        <h2 className="m-0 leading-tight text-2xl">Want Full Access?</h2>
                                    </div>
                                    <div className="text-xl pr-8">
                                        Email me at <a href="mailto:me@daikiminaki.com" target="_blank" className="underline">me@daikiminaki.com</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default InvestmentsPage;